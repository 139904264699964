import { render, staticRenderFns } from "./sendNotifications.vue?vue&type=template&id=2dcaf5be&scoped=true&"
import script from "./sendNotifications.vue?vue&type=script&lang=js&"
export * from "./sendNotifications.vue?vue&type=script&lang=js&"
import style0 from "./sendNotifications.vue?vue&type=style&index=0&id=2dcaf5be&prod&lang=sass&"
import style1 from "./sendNotifications.vue?vue&type=style&index=1&id=2dcaf5be&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dcaf5be",
  null
  
)

export default component.exports