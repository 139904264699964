<template>
  <section class="bg pd-20">
    <aRow class="notifications-page" :gutter="20">
      <aCol :span="10">
        <div class="section">
          <aRow :gutter="20">
            <aCol class="mb-40" :span="24">
              <a-alert
                type="info"
                message="Envie mensagens de notificação para os usuários do sistema:"
                banner
              />
            </aCol>

            <aCol class="mb-30" :span="24">
              <div class="travel-input-outer">
                <label for class="filled"> Destinatário </label>
                <a-select
                  class="travel-input"
                  placeholder="Função"
                  show-search
                  v-model="notification.receiver"
                  style="width: 100%"
                  @change="changeNotificationRecipient"
                >
                  <a-select-option
                    v-for="(item, index) in sendList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </div>
            </aCol>

            <aCol
              v-if="notification.receiver == 'group'"
              :span="12"
              class="mb-30"
            >
              <div class="travel-input-outer">
                <label for class="filled"> Selecione o grupo: </label>
                <a-select
                  class="travel-input"
                  placeholder="Função"
                  show-search
                  v-model="notification.role"
                  style="width: 100%"
                  @change="changeNotificationRecipient"
                >
                  <a-select-option
                    v-for="(item, index) of roles"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </aCol>

            <aCol
              v-if="notification.receiver == 'specific'"
              :span="24"
              class="mb-30"
            >
              <div class="travel-input-outer">
                <label for class="filled"> ID do usuário: </label>
                <a-select
                  class="travel-input travel-multiselector"
                  placeholder="Usuário"
                  show-search
                  v-model="notification.user_id"
                  style="width: 100%"
                  mode="multiple"
                  optionFilterProp="txt"
                  @change="changeNotificationRecipient"
                >
                  <a-select-option
                    v-for="(item, index) of listUsers"
                    :key="index"
                    :value="item.id"
                    :txt="item.first_name"
                  >
                    {{ item.id }} - {{ item.first_name }}
                    {{ item.last_name }}
                  </a-select-option>
                </a-select>
              </div>
            </aCol>

            <aCol class="mb-30" :span="24">
              <div class="travel-input-outer">
                <label for class="filled"> Assunto: </label>

                <a-input
                  v-model="notification.title"
                  placeholder="Escreva aqui..."
                />
              </div>
            </aCol>

            <aCol :span="24">
              <div class="travel-input-outer">
                <label for class="filled"> Link de destino </label>

                <a-input
                  class="travel-input"
                  v-model="notification.url"
                  placeholder="Ex: /contracts/list ou https://viajarresorts.com.br"
                />
              </div>
            </aCol>

            <aCol class="mt-30 mb-20" :span="24">
              <vue-editor
                v-model="notification.content"
                placeholder="Digite a mensagem a ser enviada..."
                :editor-toolbar="customToolbar"
                :useCustomImageHandler="true"
                @image-added="upload"
              ></vue-editor>
            </aCol>

            <aCol :span="24">
              <a-button
                type="primary"
                :loading="notification.loading"
                @click="sendNotifications()"
              >
                Enviar notificação
              </a-button>

              <a-checkbox
                class="ml-20"
                v-model="sendEmail"
                style="font-size: 12px; font-weight: 500; position: relative"
              >
                Marque para enviar também por E-mail
              </a-checkbox>
            </aCol>
          </aRow>
        </div>
      </aCol>

      <aCol :span="14">
        <div class="section">
          <aCollapse
            class="travel-filters expandable"
            activeKey="0"
            expandIconPosition="right"
            style="margin: 0 !important"
          >
            <aCollapse-panel key="1">
              <template slot="header">
                <a-icon type="filter" class="mr-5" /> FILTRAR
              </template>

              <aRow class="fiter-collumns" :gutter="20">
                <aCol :span="12">
                  <div class="travel-input">
                    <label for class="filled">Usuários</label>
                    <a-select
                      class="travel-input"
                      placeholder="Selecione um ou mais usuários"
                      optionFilterProp="txt"
                      mode="multiple"
                      v-model="notifications.filters.users.selected"
                      show-search
                      style="width: 100%"
                      @change="getNotifications()"
                    >
                      <a-select-option
                        v-for="(item, index) of notifications.filters.users
                          .list"
                        :key="index"
                        :value="item.value"
                        :txt="item.label"
                      >
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </div>
                </aCol>
                <aCol :span="12">
                  <div class="travel-input">
                    <label for class="filled">Período</label>

                    <a-range-picker
                      v-model="notifications.filters.period.selected"
                      :format="['DD/MM/YYYY']"
                      :value-format="'YYYY-MM-DD'"
                      @change="getNotifications()"
                    />
                  </div>
                </aCol>

                <aCol :span="24">
                  <div class="field payments">
                    <span class="label">Status</span>
                    <a-checkbox-group
                      style="width: 100%"
                      v-model="notifications.filters.status.selected"
                      @change="getNotifications()"
                    >
                      <a-checkbox
                        v-for="(item, index) of notifications.filters.status
                          .list"
                        :key="index"
                        :value="item.value"
                        class="f12"
                      >
                        {{ item.label }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </div></aCol
                >
              </aRow>
            </aCollapse-panel>
          </aCollapse>

          <a-table
            class="travel-table"
            :columns="columns"
            :data-source="notifications.list"
            :loading="notifications.loading"
            :pagination="false"
            @change="notificationsTableChange"
          >
            <div slot="created" slot-scope="record">
              {{ formatDateTime(record.created) }}
            </div>

            <div slot="title" slot-scope="record">
              <div class="dotted-phrase" style="width: 300px; font-weight: 600">
                {{ record.title }}
              </div>
            </div>

            <div slot="user" slot-scope="record">
              <a-tooltip>
                <template slot="title">
                  {{ record.user.first_name }}
                  {{ record.user.last_name }}
                </template>
                <span id="avatar">
                  <a-avatar
                    v-if="record.user.avatar"
                    :src="record.user.avatar"
                    :size="30"
                  />
                  <a-avatar
                    v-else
                    :size="30"
                    class="upper"
                    style="color: #f56a00; background-color: #fde3cf"
                  >
                    {{ record.user.first_name.substring(0, 1)
                    }}{{ record.user.last_name.substring(0, 1) }}
                  </a-avatar>
                </span>
              </a-tooltip>
            </div>

            <div slot="is_read" slot-scope="record">
              <a-icon
                class="f20 cgreen"
                v-if="record.is_read == 1"
                type="eye"
              />
              <a-icon
                class="f20"
                v-if="record.is_read == 0"
                type="eye-invisible"
                style="color: #ccc"
              />
            </div>

            <div slot="action" slot-scope="record" class="a-right">
              <a-popconfirm
                title="Apagar notificação?"
                ok-text="Sim"
                cancel-text="Não"
                placement="left"
                @confirm="deleteNotification(record.id)"
              >
                <a-icon class="f20 delete ml-10" type="delete" />
              </a-popconfirm>
            </div>

            <div
              slot="expandedRowRender"
              slot-scope="record"
              style="padding: 10px 0"
            >
              <div v-html="formatText(record.content)" />
            </div>
          </a-table>

          <div class="a-center">
            <a-pagination
              show-size-changer
              :default-current="notifications.pagination.page"
              :page-size.sync="notifications.pagination.perPage"
              :total="notifications.pagination.total"
              @change="notificationsChangePage"
              @showSizeChange="notificationsChangePageSize"
            >
            </a-pagination>
          </div>
        </div>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor";
import replaceOnce from "replace-once";
import roles from "@/json/roles.json";
import formatThings from "@/mixins/general/formatThings";

export default {
  components: { VueEditor },
  mixins: [formatThings],
  data() {
    return {
      sendEmail: false,
      notifications: {
        list: [],
        loading: false,
        filters: {
          users: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Lido",
                value: "1",
              },
              {
                label: "Não Lido",
                value: "not-seen",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          id: "",
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
      },
      sendList: [
        {
          label: "Todos usuários ATIVOS",
          value: "all-active",
        },
        {
          label: "Todos usuários ATIVOS e DESATIVADOS",
          value: "all",
        },
        {
          label: "Usuário específico",
          value: "specific",
        },
        {
          label: "Grupo de usuários",
          value: "group",
        },
      ],
      roles,
      columns: [
        {
          title: "",
          scopedSlots: { customRender: "user" },
          width: 60,
        },
        {
          title: "",
          scopedSlots: { customRender: "is_read" },
          key: "is_read",
          sorter: true,
          width: 40,
        },
        {
          title: "Título",
          scopedSlots: { customRender: "title" },
          width: 300,
        },
        {
          title: "Data/Hora",
          scopedSlots: { customRender: "created" },
          key: "created",
          sorter: true,
        },

        {
          title: "",
          scopedSlots: { customRender: "action" },
        },
      ],
      listUsers: [],
      usersEmailList: [],
      notification: {
        role: undefined,
        title: undefined,
        content: undefined,
        receiver: "all-active",
        user_id: undefined,
        url: undefined,
        loading: false,
      },
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"],

        [{ list: "ordered" }, { list: "bullet" }],
        [
          "link",
          "blockquote",
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["clean", "image"],
      ],
    };
  },
  beforeCreate() {
    this.$http.post("/user/read").then(({ data }) => {
      this.listUsers = data;
    });

    this.$http
      .get("/user/list?page=1&per_page=100&user_id=1")
      .then(({ data }) => {
        this.usersList = data.data;
        this.notifications.filters.users.list = data.data.map((user) => {
          return {
            label: `${user.id} - ${user.first_name} ${user.last_name}`,
            value: user.id,
          };
        });
      });
  },
  mounted() {
    this.getNotifications();
    this.getUsers({ status: 1 });
  },
  methods: {
    getUsers(filter) {
      let filters = "";
      if (filter.status) filters += `&status=${filter.status}`;
      if (filter.role) filters += `&role=${filter.role}&status=1`;
      if (filter.user_id) filters += `&id=${filter.user_id}`;

      this.$http
        .get(`/user/list?page=1&per_page=1000${filters}`)
        .then(({ data }) => {
          this.usersEmailList = data.data;
        })
        .catch(({ response }) => {
          this.usersEmailList = [];
          this.$message.error(response.data.message);
        });
    },
    changeNotificationRecipient() {
      this.usersEmailList = [];

      if (this.notification.receiver == "all-active")
        this.getUsers({ status: 1 });

      if (this.notification.receiver == "group" && this.notification.role)
        this.getUsers({ role: this.notification.role });

      if (this.notification.receiver == "specific" && this.notification.user_id)
        this.getUsers({ user_id: this.notification.user_id });

      if (this.notification.receiver == "all") this.getUsers({});
    },
    notificationsChangePageSize(current, pageSize) {
      this.notifications.pagination.page = current;
      this.notifications.pagination.perPage = pageSize;
      this.getNotifications(current);
    },
    notificationsChangePage(current) {
      this.notifications.pagination.page = current;
      this.getNotifications(current);
    },
    notificationsTableChange(pagination, filters, sorter) {
      this.notifications.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.notifications.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getNotifications();
    },
    getNotifications(samePage) {
      this.notifications.loading = true;
      let filters = "";

      if (this.notifications.filters.users.selected.length > 0) {
        filters += `&user_id=${this.notifications.filters.users.selected}`;
      }

      if (this.notifications.filters.status.selected.length > 0) {
        filters += `&is_read=${this.notifications.filters.status.selected}`;
      }

      if (this.notifications.filters.period.selected.length > 0) {
        filters += `&period=${this.notifications.filters.period.selected[0]}|${this.notifications.filters.period.selected[1]}`;
      }

      filters += `&order=${this.notifications.filters.order}&order-by=${this.notifications.filters.orderBy}`;

      this.$http
        .post(
          `/notification/list?page=${this.notifications.pagination.page}&per_page=${this.notifications.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.notifications.pagination.page = 1;
          }
          this.notifications.pagination.total = data.meta.total;
          this.notifications.pagination.totalPages = data.meta.total_pages;

          this.notifications.list = data.data;
          this.notifications.loading = false;
        })
        .catch(({ response }) => {
          this.notifications.pagination.total = response.data.meta.total;
          this.notifications.pagination.totalPages =
            response.data.meta.total_pages;

          this.notifications.list = [];
          this.notifications.loading = false;
        });
    },
    formatText(text) {
      let find = ["<p><br></p>", "<img "];
      let replace = ["", "<img id='img-uploaded' "];

      return replaceOnce(text, find, replace, "gi");
    },
    upload(file, Editor, cursorLocation) {
      var formData = new FormData();
      formData.append("file", file);

      this.$http
        .post(
          `/media/upload-to-s3?module=notes&id=options/notifications&sub-folder=`,
          formData
        )
        .then(({ data }) => {
          Editor.insertEmbed(cursorLocation, "image", data.url);
        });
    },
    deleteNotification(id) {
      this.notifications.loading = true;
      this.$http
        .post("/notification/delete", {
          id,
        })
        .then(({ data }) => {
          data;
          this.getNotifications(this.notifications.pagination.page);
          this.notifications.loading = false;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.notifications.loading = false;
        });
    },
    sendNotifications() {
      if (this.notification.content) {
        this.notification.loading = true;

        if (this.sendEmail) {
          this.usersEmailList.forEach((user) => {
            this.$http.post("/email/settings/notification/send", {
              to: {
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
              },
              subject: this.notification.title,
              content: this.notification.content,
              url: `https://${window.location.hostname}${this.notification.url}`,
            });
          });
        }

        this.$http
          .post("/notification/send", this.notification)
          .then(({ data }) => {
            this.$message.success(data.message);
            this.notification.loading = false;
            this.getNotifications();
          })
          .catch(({ response }) => {
            this.$message.error(response.message);
            this.notification.loading = false;
          });
      } else {
        this.$message.warning("Escreva uma mensagem no campo.");
      }
    },
  },
};
</script>

<style lang="sass">
.notifications-page
  .ant-table-title
    display: none
</style>

<style lang="sass" scoped>
.notifications-page
  .section
    background: #fff
    padding: 20px
    max-height: 640px
    min-height: 640px
    overflow: auto
    .delete
      color: #ccc
      transition: .3s
      cursor: pointer
    .delete:hover
      opacitiy: 1
      color: red
    label.filled
      color: #333
    .prev
      max-height: 60px
      overflow: auto
.ant-alert-info
    font-size: 12px
.label
    font-size: 11px
    font-weight: 600
    color: #444
    display: block
    z-index: 1
.quillWrapper
    border: 1px solid #eee
.ant-radio-wrapper
    font-size: 13px
    font-weight: 500
</style>
